.thing-enter {
    transform: translateY(100px);
    opacity: 0;
}

.thing-enter-active {
    transform: translateY(0);
    opacity: 1;
    transition: opacity 500ms, transform 500ms;
}

.scroll-container {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  position: relative;
}

  
  .circle {
    position: absolute;
    left: 42px;
    top: 18px;
    width: 20px;
    height: 20px;
    border-radius: 50%;
    transition: all 1s ease;
  }
  
  .grow-donut {
    position: absolute;
    left: 36px;
    top: 18px;
    width: 36px;
    height: 36px;
    border-radius: 50%;
    background-color: transparent;
    border: 7px solid #604537;
  }

.line {
  width: 2px;
  top: 0;
  margin-left: 52px;
  margin-top: 48px;
  background: linear-gradient(to bottom, #604537 70%, transparent 100%);
  position: sticky;
  height: 0;
  z-index: 10;
  transition: height 3s ease;
}

.line-grow {
  height: 70vh;
}


.exp-container {
  position: relative;
  left: 20px;
  width: calc(100% - 120px);
  margin-top: 100px; 
}