@tailwind base;
@tailwind components;
@tailwind utilities;

html {
    scroll-behavior: smooth;
}

.thing-enter {
  transform: translateY(100px);
  opacity: 0;
}

.thing-enter-active {
  transform: translateY(0);
  opacity: 1;
  transition: opacity 500ms, transform 500ms;
}

.nav-link {
  position: relative;
  display: inline-block;
  text-decoration: none;
}

.nav-link::after {
  content: '';
  position: absolute;
  width: 0%;
  height: 2px;
  bottom: -2px;
  left: 50%;
  background: #604537;
  transition: width 0.3s ease, left 0.3s ease, opacity 0.3s ease;
}

.nav-link:hover::after {
  width: 100%;
  left: 0%;
  opacity: 1;
}

.nav-bar {
  background-color: #f5f5f5;
}

.nav-bar::before {
  content: '';
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background-image: url(/public/paper.png); 
  background-size: cover; 
  background-repeat: no-repeat;
  background-position: center; 
  opacity: 0.2; 
  pointer-events: none; 
}

.group .hover-effect-icon {
  transition: transform 0.1s ease;
  transform: translate(0, 0);
}

.group:hover .hover-effect-icon {
  transform: translate(0.15rem, -0.15rem);
  transition: transform 0.1s ease;
}